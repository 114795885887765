import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataFetcher } from "components/DataFetcher";
import { useState } from "react";
import "./DisplaySeats.scss";

const DisplaySeats = ({ maxSeats, usedSeats }) => {
	const fetchData = { url: "/api/v3/ContactDetails" };
	const [data, setData] = useState(null);
	const barWidthPercent = (usedSeats / maxSeats) * 100;

	return (
		<div className="displaySeats">
			<h3>
				You have {maxSeats - usedSeats} seat(s) remaining
				<span className="popup-container">
					<FontAwesomeIcon icon={faCircleInfo} className="info-icon" />
					<DataFetcher fetchData={fetchData} setData={setData}>
						<div className="popup">
							<p>To add more seats to your package, please contact us at ITEL on the number</p>
							{data && <p><strong>{data.ContactNumber}</strong></p>}
						</div>
					</DataFetcher>
				</span>
			</h3>
			<div className="displaySeats-amount">
				<span className="displaySeats-amount-bar" style={{ width: `${barWidthPercent}%` }}></span>
				<p>{usedSeats} / {maxSeats}</p>
			</div>
		</div>
	);
};

export default DisplaySeats;
