import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, ButtonType } from "components/Button/Button";
import "./Courses.scss";

const Courses = ({ contentItems }) => {

	useEffect(() => {
		if (contentItems) {
			for (let item of contentItems) {
				item.SubContent.sort((a, b) => { return a.Position - b.Position });
			}
		}
	}, [contentItems]);

	const getViewFullCourseLink = (course) => {
		let result = "/course-view#courseId-" + course.Id;
		if (course.SubContent.length > 1) {
			result += "-moduleId-" + course.SubContent[0].Id;
		}
		return result;
	};

	const getButton = (course) => {
		const hasContent = course.SubContent.some(value => value.ContentType.length);
		if (!hasContent) {
			return (<Button text={"Coming Soon"} disabled={true} />);
		}
		
		let text = "Start";
		const courseStarted = course.SubContent.some(value => value.Progress > 0);
		if (courseStarted) {
			const courseCompleted = course.SubContent.every(value => value.Progress === 0);
			text = courseCompleted ? "Restart" : "Continue";
		}
		return (<Button
			text={text}
			to={`/player#courseId-${course.Id}-moduleId-${course.LastViewed.ContentId}-subModuleId-${course.LastViewed.SubContentId}`}
		/>);
	};

	return (
		<>
			{contentItems && contentItems.map(course => {
				return (
					<div className="course" key={course.Id}>
						<h2>{course.Name}</h2>
						<div className="modules">
							<div className="course-image" style={{ backgroundImage: `url(${course.ImageUri})` }} />
							{course.SubContent.slice(0, 4).map(module => (
								<Link to={`/course-view#courseId-${course.Id}-moduleId-${module.Id}`} className="module" key={module.Id}>
									<p>{module.Name}</p>
									<div className="percent">
										<span className="percent-bar" style={{ width: `${module.Progress}%` }}></span>
										<h4>{module.Progress}%</h4>
									</div>
								</Link>
							))}
						</div>
						<div className="buttons">
							<div className="buttons-left">
								<Button to={getViewFullCourseLink(course)} type={ButtonType.Tertiary} text="View Full Course" scroll={true} />
							</div>
							<div className="buttons-right">
								{getButton(course)}
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};

export default Courses;
