import { Button } from "components/Button/Button";
import "./SearchResultItem.scss";

const SearchResultItem = ({ searchResults, searchTerm }) => {

	const highlightTerm = (text, term) => {
		if (!term) {
			return text;
		}

		const regex = new RegExp(`(${term})`, 'gi');
		const parts = text.split(regex);
		return parts.map((part, index) =>
			regex.test(part) ? <span key={index} className="searchTerm">{part}</span> : part
		);
	};

	const getButtonText = (progress) => {
		switch (progress) {
			case 0:
				return "Start";
			case 100:
				return "Restart";
			default:
				return "Continue";
		}
	};

	if (!searchResults || searchResults.length === 0) {
		return <div>No results found.</div>;
	}

	return (
		<>
			{searchResults.map(searchResult => (
				<div key={searchResult.Id} className="searchResultItem">
					<div className="searchResultItem-course">
						<div className="searchResultItem-course-details">
							<h2>{highlightTerm(searchResult.ModuleName, searchTerm)}</h2>
							<div>
								<Button
									to={`/player#courseId-${searchResult.CategoryId}-moduleId-${searchResult.CourseId}-subModuleId-${searchResult.ModuleId}`}
									text={getButtonText(searchResult.Progress)}
								/>
							</div>
						</div>
						<div className="searchResultItem-course-image">
							<img alt={searchResult.Name} src={searchResult.Image} />
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default SearchResultItem;
