import { faPersonChalkboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonType } from "components/Button/Button";
import ContactDetails from "components/ContactDetails/ContactDetails";
import { DataFetcher } from "components/DataFetcher";
import Pod from "components/Pod/Pod";
import useBranding from "hooks/useBranding";
import { useState } from "react";
import "./Help.scss";

const Help = () => {
	const { gradient, watermark } = useBranding();
	const guruUrls = (sessionStorage.gurus) ? JSON.parse(sessionStorage.gurus) : [];
	const fetchData = { url: "/api/v3/ContactDetails" };
	const [data, setData] = useState(null);

	const renderGuruBlocks = () => {
		if (!guruUrls.length) {
			return null;
		}
		if (guruUrls.length === 1) {
			return <div className="help-guru">
				<FontAwesomeIcon icon={faPersonChalkboard} className="fa-4x" />
				<p>Need expert help with a part of your phone system? Book a free 1 to 1 guru session here.</p>
				<Button type={ButtonType.Primary} to={guruUrls[0].GuruUrl} text="Book Session" newTab />
			</div>;
		}

		return (guruUrls.map(guru => (
			<div className="help-guru" key={guru.Id}>
				<FontAwesomeIcon icon={faPersonChalkboard} className="fa-4x" />
				<p>Need expert help with a part of your {guru.Name} phone system? Book a free 1 to 1 guru session here.</p>
				<Button type={ButtonType.Primary} to={guruUrls[0].GuruUrl} text="Book Session" newTab />
			</div>
		)));
	};

	return (
		<div className="help grid-two-col gutters">
			<Pod title="Help" gradient={gradient} watermark={watermark}>
				<div className="help-item">
					<p>Our support team are always happy to answer any questions you have about the site or your subscription, please call us during UK business hours or email any time using the contact details on this page.</p>
					{data && data.IsUpsold === true && <p>Should you wish to cancel your access to any ucSKILLS training content, please email the iTEL ucSKILLS support team with the subject line "Cancel Access", detailing the specifics of your cancellation request.</p>}
				</div>
				{renderGuruBlocks()}
			</Pod>
			<Pod title="Contact Us" gradient={gradient} watermark={watermark}>
				<DataFetcher fetchData={fetchData} setData={setData}>
					{data && <ContactDetails number={data.ContactNumber} email={data.ContactEmail} />}
				</DataFetcher>
			</Pod>
		</div>
	);
};

export default Help;