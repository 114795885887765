import { Button, ButtonType } from "components/Button/Button";
import ContactDetails from "components/ContactDetails/ContactDetails";
import { DataFetcher } from "components/DataFetcher";
import Pod from "components/Pod/Pod";
import useBranding from "hooks/useBranding";
import useUserData from "hooks/useUserData";
import { useCallback, useEffect, useRef, useState } from "react";
import { getFetchOptions } from "../../utils/fetch";
import "./Profile.scss";

const Profile = () => {
	const { gradient, watermark } = useBranding();
	const { userData } = useUserData();
	const [fetchData, setFetchData] = useState({ url: "/api/v3/ContactDetails" });
	const [consentData, setConsentData] = useState(null);
	const initialListData = useRef({});
	const [currentListData, setCurrentListData] = useState({});
	const [contactDetails, setContactDetails] = useState(null);
	const [showSave, setShowSave] = useState(false);

	/**
	 * @note Fetch request different success return data:
	 *   1) GET /api/v3/ContactDetails - ContactDetailsDto
	 *   2) GET /api/v3/Profile/GetMarketingConsentPreferences - MarketingConsentPreferencesDto
	 *   3) PUT /api/v3/Profile/UpdateMarketingConsentPreferences - TRUE
	 */
	const handleData = useCallback((data) => {

		const handleMarketingConsentData = () => {
			setConsentData({
				isAdmin: data.IsAdmin,
				showMarketing: data.ShowMarketing,
				hasSoftBusiness: data.HasSoftBusiness
			});
			const initial = {
				serviceListsEnabled: data.ServiceListsEnabled,
				productMarketingListsEnabled: data.ProductMarketingListsEnabled,
				softBusinessMarketingListsEnabled: data.SoftBusinessMarketingListsEnabled
			};
			initialListData.current = initial;
			setCurrentListData(initial);
		};

		if (data) {
			if (typeof data === "object") {
				console.debug(data);
				if ("ShowMarketing" in data) {
					handleMarketingConsentData();
				}
				else if ("IsUpsold" in data) {
					setContactDetails(data);
					setFetchData({ url: "/api/v3/Profile/GetMarketingConsentPreferences" });
				}
			}
			else if (typeof data === "boolean") {
				setFetchData({ url: "/api/v3/Profile/GetMarketingConsentPreferences" });
			}
		}
	}, []);

	// Effect used for changes that determine Save button visibility.
	useEffect(() => {
		const hasChanges =
			currentListData.serviceListsEnabled !== initialListData.current.serviceListsEnabled ||
			currentListData.productMarketingListsEnabled !== initialListData.current.productMarketingListsEnabled ||
			currentListData.softBusinessMarketingListsEnabled !== initialListData.current.softBusinessMarketingListsEnabled;
		setShowSave(hasChanges);
	}, [currentListData]);

	const handleChangeInput = (fields) => {
		setCurrentListData((prev) => {
			return { ...prev, ...fields }
		});
	};

	const handlePreferencesChange = () => {
		const options = getFetchOptions({
			method: "PUT",
			body: {
				ServiceListsEnabled: currentListData.serviceListsEnabled,
				ProductMarketingListsEnabled: currentListData.productMarketingListsEnabled,
				SoftBusinessMarketingListsEnabled: currentListData.softBusinessMarketingListsEnabled
			}
		});
		setFetchData({ url: "/api/v3/Profile/UpdateMarketingConsentPreferences", options: options });
	};

	const MarketingListSection = (
		<>
			{consentData && consentData.showMarketing === true && <>
				<h3>Marketing Consent</h3>
				<div>
					<p>Review and change your contact preferences</p>
					<p className="consent-intro"><strong>I allow iTEL to contact me with:</strong></p>
					<div>
						<label className="checkbox-row">
							<input
								type="checkbox"
								checked={currentListData.serviceListsEnabled}
								onChange={e => handleChangeInput({ serviceListsEnabled: e.target.checked })}
							/>
							<p>Product and Service Updates (recommended)</p>
						</label>
					</div>
					{consentData.isAdmin &&
						<>
							<div>
								<label className="checkbox-row">
									<input
										type="checkbox"
										checked={currentListData.productMarketingListsEnabled}
										onChange={e => handleChangeInput({ productMarketingListsEnabled: e.target.checked })}
									/>
									<p>Product and Service Promotions and Deals</p>
								</label>
							</div>
							{consentData.hasSoftBusiness &&
								<div>
									<label className="checkbox-row">
										<input
											type="checkbox"
											checked={currentListData.softBusinessMarketingListsEnabled}
											onChange={e => handleChangeInput({ softBusinessMarketingListsEnabled: e.target.checked })}
										/>
										<p>Soft and Business Skills Promotions and Deals</p>
									</label>
								</div>
							}
						</>
					}
					<div className="save">
						{showSave && <Button type={ButtonType.Primary} text="Save" onClick={handlePreferencesChange} />}
					</div>
				</div>
			</>}
		</>
	);

	return (
		<div className="profile grid-two-col gutters">
			<DataFetcher fetchData={fetchData} setData={handleData}>
				<Pod title="Profile Management" gradient={gradient} watermark={watermark}>
					<div className="profile-details">
						<h3>Email</h3>
						<p>{userData.Email}</p>
						<h3>Name</h3>
						<p>{userData.Name}</p>
						<h3>Company</h3>
						<p>{userData.Company}</p>
							{MarketingListSection}
					</div>
				</Pod>
				<Pod title="Contact Us" gradient={gradient} watermark={watermark}>
					{contactDetails && <ContactDetails number={contactDetails.ContactNumber} email={contactDetails.ContactEmail} />}
				</Pod>
			</DataFetcher>
		</div>
	);
};

export default Profile;