import { DataFetcher } from "components/DataFetcher";
import ListNavigation from "components/ListNavigation/ListNavigation";
import Pod from "components/Pod/Pod";
import useBranding from "hooks/useBranding";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "./CourseView.scss";
import CourseItem from "./components/CourseItem";
import useScrollToPageArea from "hooks/useScrollToPageArea";

const MODULE_ID_TEXT = "moduleId";

const CourseView = () => {
	const { hash } = useLocation();
	const { gradient, watermark } = useBranding();
	const [fetchData, setFetchData] = useState({ url: "/api/v3/ContactDetails" });
	const [data, setData] = useState(null);
	const contactDetails = useRef({});
	const [expandedModuleId, setExpandedModuleId] = useState(null);
	const [courseAndModuleData, setCourseAndModuleData] = useState(null);
	const { scroll } = useScrollToPageArea();
	const [scrollTarget, setScrollTarget] = useState(null); // Track scroll target

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	/*
	 * Effect for processing the returned data from the DataFetcher.
	 * This needs to be retained as a useEffect (instead of using a useCallback) - otherwise the scrolling doesn't work.
	 */
	useEffect(() => {
		if (data) {
			if (data.ContactNumber) {
				contactDetails.current = data;
				setFetchRequestData("/api/v3/CoursesAndModules/ByCurrentUser");
			}
			else if (!courseAndModuleData) {
				for (let item of data) {
					item.SubContent.sort((a, b) => {
						return a.Position - b.Position;
					});
				}
				setCourseAndModuleData(data);
			}
		}
	}, [data, setFetchRequestData, courseAndModuleData]);
	
	const parseHashForModuleId = (id) => {
		let moduleIdIndex = id.indexOf(MODULE_ID_TEXT);
		if (moduleIdIndex !== -1) {
			const moduleId = Number(id.substring(moduleIdIndex + MODULE_ID_TEXT.length + 1));
			setExpandedModuleId(moduleId);
		}
	};
	
	// Effect that processes the URL hash.
	useEffect(() => {
		if (hash) {
			const element = hash.slice(1);
			setScrollTarget(element);
			parseHashForModuleId(element);
		}
	}, [hash]);
	
	// Effect for Scrolling.
	useEffect(() => {
		if (scrollTarget && courseAndModuleData) {
			scroll(scrollTarget); 
			setScrollTarget(null); 
		}
	}, [scrollTarget, courseAndModuleData, scroll]);

	const handleExpandModule = (moduleId) => {
		setExpandedModuleId(prev => (prev === moduleId ? null : moduleId)); 
	};

	return (
		<DataFetcher fetchData={fetchData} setData={setData}>
			<div className="grid-two-col gutters">
				<Pod title="Courses" gradient={gradient} watermark={watermark}>
					{courseAndModuleData && (
						<div className="list-view">
							{courseAndModuleData.map((course) => (
								<CourseItem
									key={course.Id}
									course={course}
									expandedModuleId={expandedModuleId}
									onExpand={handleExpandModule}
									contactDetails={contactDetails.current}
								/>
							))}
						</div>
					)}
				</Pod>
				<Pod title="Course Navigation" gradient={gradient} watermark={watermark} mobileOrder={1} sticky>
					{courseAndModuleData && <ListNavigation items={courseAndModuleData} />}
				</Pod>
			</div>
		</DataFetcher>
	);
};

export default CourseView;
