import { useCallback, useEffect, useState } from "react";

const usePlayerMessageReceiver = (expectedMessageOrigin, messageMap, processing) => {
	const [messageQueue, setMessageQueue] = useState([]);

	useEffect(() => {
		if (messageQueue.length > 0 && processing.current === 0) {
			const oldestMessage = messageQueue[0];
			let messageData = JSON.parse(oldestMessage.data);
			if (messageMap[messageData.eventName]) {
				setMessageQueue(queue => queue.filter(msg => msg.queueId !== oldestMessage.queueId));
				messageMap[messageData.eventName](messageData);
			}
		}
	}, [messageQueue, messageMap, processing]);

	// Adding a message to the queue which is processed via a useEffect, as opposed to processing it directly, keeps the state of Player.jsx.
	const handleMessageFromContent = useCallback((message) => {
		if (message.origin === expectedMessageOrigin) {
			message.queueId = messageQueue.length;
			setMessageQueue(queue => [...queue, message]);
		}
	}, [expectedMessageOrigin, messageQueue.length]);

	// Effect for message handling.
	useEffect(() => {
		if (expectedMessageOrigin) {
			window.addEventListener("message", handleMessageFromContent);
		}
		return () => {
			window.removeEventListener("message", handleMessageFromContent);
		};
	}, [expectedMessageOrigin, handleMessageFromContent]);
};

export default usePlayerMessageReceiver;