import { Button, ButtonType } from "components/Button/Button";
import "./AdminCentre.scss";

const AdminCentre = () => {
	return (
		<div className="admin-centre">
			<Button type={ButtonType.Secondary} text="User Management" to="/usermanagement" />
			<Button type={ButtonType.Secondary} text="User Timeline Report" to="/timeline" />
		</div>
	);
};

export default AdminCentre;