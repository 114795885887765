import React from 'react';
import './UserEditor.scss';
import { Button, ButtonType } from 'components/Button/Button';
import { ActionType } from '../../ActionType';

const ConfirmationDialog = ({ confirmation, users, formData, handleConfirm, handleCancel }) => {
	return (
		<div className="confirmation-message">
			<p>
				{confirmation.action === ActionType.Delete && (
					<>Are you sure you want to <span>delete</span> the following users?</>
				)}
				{confirmation.action === ActionType.Resend && (
					<>Are you sure you want to <span>resend the registration email</span> to the following users?</>
				)}
				{confirmation.action === ActionType.Update && (
					<>Are you sure you want to <span>update</span> the following users?</>
				)}
				{confirmation.action === ActionType.Add && (
					<>Are you sure you want to <span>add</span> the following user?</>
				)}
			</p>
			<ul className="delete-user-list">
				{confirmation.action !== ActionType.Add ? (
					users.map((user) => (
						<li key={user.Id} className="delete-user-list-item">{user.FullName}</li>
					))
				) : (
					<li className="delete-user-list-item">
						{formData.firstName} {formData.lastName} ({formData.username})
					</li>
				)}
			</ul>
			<div className="modal-buttons">
				<Button type={ButtonType.Primary} text="Yes" onClick={handleConfirm} />
				<Button type={ButtonType.Secondary} text="No" onClick={handleCancel} />
			</div>
		</div>
	);
};

export default ConfirmationDialog;
