import { DataFetcher } from "components/DataFetcher";
import Pod from "components/Pod/Pod";
import useBranding from "hooks/useBranding";
import useUserData from "hooks/useUserData";
import { useState } from "react";
import { UserRoles } from "utils/userRoles";
import "./Dashboard.scss";
import AdminCentre from "./components/AdminCentre";
import Courses from "./components/Courses";
import QuickSearch from "features/Search/QuickSearch";

const Dashboard = () => {
	const { gradient, watermark } = useBranding();
	const fetchData = { url: "/api/v3/CoursesAndModules/ByCurrentUser" };
	const [data, setData] = useState(null);
	const { userData } = useUserData();

	return (
		<DataFetcher fetchData={fetchData} setData={setData}>
			<div className="grid-two-col gutters">
				<Pod title="Courses" gradient={gradient} watermark={watermark}>
					<Courses contentItems={data} />
				</Pod>
				<div>
					<Pod title="Search" gradient={gradient} watermark={watermark}>
						<QuickSearch bentobox={true} />
					</Pod>
					{userData.UserLevel !== UserRoles.Learner &&
						<Pod title="Admin Centre" gradient={gradient} watermark={watermark}>
							<AdminCentre />
						</Pod>
					}
				</div>
			</div>
		</DataFetcher>
	);
};

export default Dashboard;
