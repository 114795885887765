import React from 'react';
import './UserEditor.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const UserForm = ({
	users,
	edit,
	data,
	formData,
	handleChange,
	handleCheckboxChange,
	handleRadioChange,
	handleIconClick,
	contentGroupOptions,
	deviceGroupOptions,
	contentGroupUserCount,
	deviceGroupUserCount,
	firstNameRef,
	lastNameRef,
}) => {
	const contentGroupsClass = contentGroupOptions.length > 5 ? 'overflowed' : '';
	const deviceGroupsClass = deviceGroupOptions.length > 5 ? 'overflowed' : '';

	const getContentGroupText = (group) => {
		let result = group.Name;
		if (contentGroupUserCount[group.Id] > 0) {
			result += [" (", contentGroupUserCount[group.Id], " user", (contentGroupUserCount[group.Id] > 1 ? "s" : ""), ")"].join("");
		}
		return result;
	};

	return (
		<form>
			<div className="user-management-modal-grid">
				{users.length < 2 ? (
					<>
						<div>
							<label>
								<h4>First Name</h4>
								<input
									type="text"
									name="firstName"
									value={formData.firstName}
									onChange={handleChange}
									ref={firstNameRef}
									disabled={edit && users.length > 1}
									required={!edit}
									placeholder={edit && users.length > 1 ? '-' : ''}
								/>
							</label>
						</div>
						<div>
							<label>
								<h4>Last Name</h4>
								<input
									type="text"
									name="lastName"
									value={formData.lastName}
									onChange={handleChange}
									ref={lastNameRef}
									disabled={edit && users.length > 1}
									required={!edit}
									placeholder={edit && users.length > 1 ? '-' : ''}
								/>
							</label>
						</div>
						<div>
							<label>
								<h4>Email</h4>
								<input
									type="email"
									name="username"
									value={formData.username}
									onChange={handleChange}
									disabled={edit}
									required={!edit}
									placeholder={edit && users.length > 1 ? '-' : ''}
								/>
							</label>
						</div>
					</>
				) : (
					<div>
						<h4>Selected Users</h4>
						<ul className="delete-user-list">
							{users.map((user) => (
								<li key={user.Id} className="delete-user-list-item">{user.FullName}</li>
							))}
						</ul>
					</div>
				)}
				<div>
					<label>
						<h4>Access Level</h4>
						<select
							name="roleId"
							value={formData.roleId}
							onChange={handleChange}
							required
							disabled={formData.roleId === -1}
						>
							{data?.RoleData?.map((role) => (
								<option key={role.Id} value={role.Id}>{role.Name}</option>
							))}
							{formData.roleId === -1 && (
								<option value={-1} disabled>Mixed</option>
							)}
						</select>
					</label>
				</div>
				<div className="user-management-modal-grid-colOneSpanTwo checkbox-list">
					<label><h4>Content Groups</h4></label>
					<div className={contentGroupsClass}>
						{contentGroupOptions.map((group) => {
							const isPresentForSome = users.some((user) => (user.ContentGroups || []).map((g) => g.Id).includes(group.Id));
							const isPresentForAll = users.every((user) => (user.ContentGroups || []).map((g) => g.Id).includes(group.Id));
							const isMixed = isPresentForSome && !isPresentForAll;
							const isChecked = formData.contentGroups.some((g) => g.Id === group.Id);

							return (
								<div key={group.Id}>
									<label>
										<p className={`contentGroup ${isMixed ? 'mixed' : ''}`}>
											<input
												type="checkbox"
												name={group.Name}
												checked={isChecked}
												onChange={(e) => handleCheckboxChange(e, group, 'contentGroups')}
											/>
											{getContentGroupText(group)}
										</p>
									</label>
								</div>
							);
						})}
					</div>
				</div>
				{deviceGroupOptions.length > 0 && (
					<div className="user-management-modal-grid-colTwoSpanTwo checkbox-list">
						<label><h4>Device Groups</h4></label>
						<div className={deviceGroupsClass}>
							{deviceGroupOptions.map((group) => {
								const isChecked = formData.deviceGroup?.Id === group.Id;
								const userCount = deviceGroupUserCount[group.Id] || 0;

								return (
									<div key={group.Id}>
										<label>
											<p className="contentGroup">
												<input
													type="radio"
													name="deviceGroup"
													checked={isChecked}
													onChange={(e) => handleRadioChange(e, group)}
												/>
												{group.Name} {userCount > 0 && <>({userCount} users)</>}
												<FontAwesomeIcon
													icon={faPenToSquare}
													onClick={() => handleIconClick(group)}
													className="edit-icon"
													style={{ marginLeft: '10px', cursor: 'pointer' }}
												/>
											</p>
										</label>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</form>
	);
};

export default UserForm;
