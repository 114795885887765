import { Button } from "components/Button/Button";
import "./CourseItem.scss";
import ModuleItem from "./ModuleItem";

const CourseItem = ({ course, expandedModuleId, onExpand, contactDetails }) => {

	const getContinueSection = () => {
		course.hasContent = course.SubContent.some(value => value.ContentType.length);
		if (course.hasContent) {
			return (
				<div className="list-view-item-continue">
					<p><strong>{course.LastViewed.Continuing ? "Last Viewed" : "Get Started"}:</strong> {course.LastViewed.SubContentName}</p>
					<Button
						text={course.LastViewed.Continuing ? "Continue" : "Start"}
						to={`/player#courseId-${course.Id}-moduleId-${course.LastViewed.ContentId}-subModuleId-${course.LastViewed.SubContentId}`}
					/>
				</div>
			);
		}
		return null;
	};

	return (
		<div className="list-view-item" id={"courseId-" + course.Id}>
			<div className="list-view-item-details">
				<div className="list-view-item-details-text">
					<h2>{course.Name}</h2>
					<p>{course.Description}</p>
				</div>
				<div className="list-view-item-details-image">
					<img alt={course.Name} src={course.ImageUri} />
				</div>
			</div>
			{getContinueSection()}
			<h3>Modules</h3>
			{course.SubContent.map((module) => (
				<ModuleItem
					key={module.Id}
					course={course}
					module={module}
					expandedModuleId={expandedModuleId}
					onExpand={onExpand}
					contactDetails={contactDetails}
				/>
			))}
		</div>
	);
};

export default CourseItem;
