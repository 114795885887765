import { faCircleQuestion, faMagnifyingGlass, faPersonChalkboard, faRightFromBracket, faUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "assets/images/bob-ucskills-logo.svg";
import useLogout from "hooks/useLogout";
import useUserData from "hooks/useUserData";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchBox from "features/Search/SearchBox";
import "./Header.scss";

const Header = () => {
	const [sidebarToggle, setSidebarToggle] = useState(false);
	const [mobileSearchToggle, setMobileSearchToggle] = useState(false);
	const [newPrimaryHex, setNewPrimaryHex] = useState('#143872');
	const [newSecondaryHex, setNewSecondaryHex] = useState('#f68b1f');
	const navigate = useNavigate();
	const { logout } = useLogout(navigate);
	const { userData } = useUserData();
	const guruUrls = (sessionStorage.gurus) ? JSON.parse(sessionStorage.gurus) : [];

	const sidebarRef = useRef(null); // Reference to the sidebar
	const hamburgerRef = useRef(null); // Reference to the hamburger button

	useEffect(() => {
		const brandingItem = sessionStorage.getItem("branding");
		if (brandingItem && brandingItem[0] === '{') {
			const branding = JSON.parse(brandingItem);
			setNewPrimaryHex('#' + branding.PrimaryColour);
			setNewSecondaryHex('#' + branding.SecondaryColour);
		}
	}, []);

	const closeSidebar = () => {
		setSidebarToggle(false);
	};

	const toggleSidebar = () => {
		setSidebarToggle(!sidebarToggle);
	};

	useEffect(() => {
		const handleScroll = () => {
			if (sidebarToggle) {
				closeSidebar();
			}
		};

		const handleClickOutside = (event) => {
			if (
				sidebarRef.current &&
				!sidebarRef.current.contains(event.target) &&
				hamburgerRef.current &&
				!hamburgerRef.current.contains(event.target)
			) {
				closeSidebar();
			}
		};

		if (sidebarToggle) {
			window.addEventListener("scroll", handleScroll);
			document.addEventListener("mousedown", handleClickOutside);
		}

		return () => {
			window.removeEventListener("scroll", handleScroll);
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [sidebarToggle]);

	const toggleMobileSearch = () => {
		setMobileSearchToggle(!mobileSearchToggle);
	};

	const handleSearch = (searchValue) => {
		navigate("/searchresults", { state: searchValue });
	};

	const handleLogout = () => {
		logout();
	};

	const renderGuruUrls = () => {
		if (!guruUrls.length) {
			return null;
		}
		if (guruUrls.length === 1) {
			return (
				<div className="sidebar-links-link">
					<FontAwesomeIcon icon={faPersonChalkboard} />
					<Link className="sidebar-links-link-text" to={guruUrls[0].GuruUrl} onClick={toggleSidebar} target="_blank">
						Book a Guru
					</Link>
				</div>
			);
		}

		return (guruUrls.map(guru => (
			<div key={guru.Id} className="sidebar-links-link">
				<FontAwesomeIcon icon={faPersonChalkboard} />
				<Link className="sidebar-links-link-text" to={guru.GuruUrl} onClick={toggleSidebar} target="_blank">
					Book a {guru.Name} Guru
				</Link>
			</div>
		)));
	};

	return (
		<div className="header-container gutters">
			<div className={`header ${mobileSearchToggle ? 'hide' : ''}`}>
				<style>
					{`:root { 
                    --primary-custom-color: ${newPrimaryHex}; 
                    --secondary-custom-color: ${newSecondaryHex};
                    }`}
				</style>
				<div className="header-logo">
					<Link to="/dashboard">
						<img alt="UCSkills" src={logo} />
					</Link>
				</div>

				<div className="header-search">
					<div className="header-search-mobile" onClick={toggleMobileSearch}>
						<FontAwesomeIcon icon={faMagnifyingGlass} />
					</div>
					<div className="header-search-desktop">
						<SearchBox handleClick={handleSearch} />
					</div>
				</div>

				<div className={`header-hamburger ${sidebarToggle ? 'header-hamburger-open' : ''}`} onClick={toggleSidebar} ref={hamburgerRef}>
					<span className="header-hamburger-bar header-hamburger-bar-one"></span>
					<span className="header-hamburger-bar header-hamburger-bar-two"></span>
					<span className="header-hamburger-bar header-hamburger-bar-three"></span>
				</div>

				{/*Sidebar*/}
				<div className={`sidebar ${sidebarToggle ? 'sidebar-open' : ''}`} ref={sidebarRef}>
					<h3>{userData.Name}</h3>
					<p>{userData.Email}</p>
					<p>{userData.Company}</p>
					<div className="sidebar-links">
						<div className="sidebar-links-link">
							<FontAwesomeIcon icon={faCircleQuestion} />
							<Link className="sidebar-links-link-text" to="/help" onClick={toggleSidebar}>
								Help
							</Link>
						</div>
						{renderGuruUrls()}
						<div className="sidebar-links-link">
							<FontAwesomeIcon icon={faUser} />
							<Link className="sidebar-links-link-text" to="/profile" onClick={toggleSidebar}>
								Profile Management
							</Link>
						</div>
						<div className="sidebar-links-link">
							<FontAwesomeIcon icon={faRightFromBracket} className="secondary" />
							<Link className="sidebar-links-link-text secondary" onClick={() => { toggleSidebar(); handleLogout(); }}>
								Sign Out
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className={`mobileSearch gutters ${mobileSearchToggle ? 'show' : ''}`}>
				<div className="mobileSearch-search">
					<SearchBox handleClick={handleSearch} />
				</div>
				<div className="mobileSearch-close" onClick={toggleMobileSearch}>
					<FontAwesomeIcon icon={faXmark} />
				</div>
			</div>
		</div>
	);
};

export default Header;
