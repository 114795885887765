import { useState, useEffect, useCallback, useRef } from 'react';
import './UserEditor.scss';
import { getFetchOptions } from 'utils/fetch';
import { DataFetcher } from '../../../../components/DataFetcher';
import ConfirmationDialog from './ConfirmationDialog';
import UserForm from './UserForm';
import ActionButtons from './ActionButtons';
import { ActionType } from '../../ActionType';

const UserEditor = ({ edit, companyId, users = [], onClose }) => {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		username: '',
		roleId: 0,
		contentGroups: [],
		deviceGroup: null,
	});
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const [initialUsers, setInitialUsers] = useState([]);
	const [updatedUsers, setUpdatedUsers] = useState([]);
	const [finalUsers, setFinalUsers] = useState();
	const [deviceGroupUserCount, setDeviceGroupUserCount] = useState({});
	const [contentGroupUserCount, setContentGroupUserCount] = useState({});
	const [disableDelete, setDisableDelete] = useState(false);
	const [confirmation, setConfirmation] = useState({ action: null, visible: false });
	const [canSave, setCanSave] = useState(false); // Track if Save button should be enabled
	const [data, setData] = useState(null);
	const [fetchData, setFetchData] = useState({});
	const [fetchStep, setFetchStep] = useState(1);

	const contentGroupOptions = data?.ContentGroupData ?? [];
	const deviceGroupOptions = data?.DeviceGroupData || [];

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	useEffect(() => {
		setFetchStep(1);
	}, []);

	useEffect(() => {
		const handleFetchStep = () => {
			let options;
			switch (fetchStep) {
				case 1:
					setFetchRequestData(`/api/CompanyUser/CreationPrerequisites/${companyId}/-99`);
					break;
				case 2:
					const selectedIds = users.map(user => user.Id);
					options = getFetchOptions({
						method: "DELETE",
						body: selectedIds
					});
					setFetchRequestData("/api/v3/CompanyUser", options);
					break;
				case 3:
					const selectedUsernames = users.map(user => user.Username);
					options = getFetchOptions({
						method: "POST",
						body: selectedUsernames
					});
					setFetchRequestData("/api/v3/ResendCompleteRegistration", options);
					break;
				case 4: // Update
					options = getFetchOptions({
						method: "PUT",
						body: {
							Users: finalUsers
						}
					});
					setFetchRequestData("/api/CompanyUser", options);
					break;
				case 5: // Add
					options = getFetchOptions({
						method: "PUT",
						body: {
							SingleUser: {
								Id: -1,
								CompanyId: companyId,
								FirstName: formData.firstName,
								LastName: formData.lastName,
								Username: formData.username,
								RoleId: formData.roleId,
								CreatedOnRegistration: false,
								Active: true,
								ContentGroups: formData.contentGroups
							}
						}
					});
					setFetchRequestData("/api/CompanyUser", options);
					break;
				case 6:
					if (onClose) {
						onClose();
					}
					break;
				default:
					break;
			}
		};
		handleFetchStep();
	}, [fetchStep, setFetchRequestData]);

	useEffect(() => {
		if (data && !data.ContentGroupData) {
			setFetchStep(6);
		}
	}, [data]);

	useEffect(() => {
		if (edit && users.length > 0) {
			setInitialUsers(users.map(user => ({ ...user })));
			setUpdatedUsers(users.map(user => ({ ...user })));
			if (users.length === 1) {
				const user = users[0];
				setFormData({
					firstName: user.FullName ? user.FullName.split(' ')[0] : '',
					lastName: user.LastName || '',
					username: user.Username || '',
					roleId: user.RoleId || 0,
					contentGroups: user.ContentGroups
						.filter(group => group.Id && group.Name) // Filter out invalid groups
						.map(group => ({ Id: group.Id, Name: group.Name })) || [],
					deviceGroup: user.DeviceGroups?.[0] || null
				});
				console.debug(formData);
			} else {
				const accessLevels = users.map(user => user.RoleId);
				const uniqueAccessLevels = [...new Set(accessLevels)];

				const mixedAccessLevel = (uniqueAccessLevels.length === 1) ? uniqueAccessLevels[0] : -1;

				const uniqueDeviceGroups = users
					.map(user => user.DeviceGroups[0]?.Id)
					.every(id => id === users[0].DeviceGroups[0]?.Id);

				const contentGroupCount = users.reduce((acc, user) => {
					user.ContentGroups.forEach(group => {
						acc[group.Id] = (acc[group.Id] || 0) + 1;
					});
					return acc;
				}, {});

				const updatedContentGroups = contentGroupOptions.map(group => {
					const userCount = contentGroupCount[group.Id] || 0;
					if (userCount === users.length) {
						// Fully selected
						return { Id: group.Id, Name: group.Name };
					} else if (userCount > 0) {
						// Partially selected
						return { Id: group.Id, Name: group.Name, mixed: true };
					}
					// Not selected
					return null;
				}).filter(Boolean);

				setFormData({
					firstName: '',
					lastName: '',
					username: '',
					roleId: mixedAccessLevel,
					contentGroups: updatedContentGroups,
					deviceGroup: uniqueDeviceGroups ? users[0].DeviceGroups?.[0] : null,
				});
			}
		} else if (!edit && data?.RoleData?.length > 0 && formData.roleId === 0) {
			// Only update the formData if it's not already set
			setFormData(prevData => ({
				...prevData,
				roleId: data.RoleData[0].Id,
				contentGroups: []
			}));
		}

		const groupCount = users.reduce((acc, user) => {
			const deviceGroup = user.DeviceGroups?.[0]?.Id;
			if (deviceGroup) {
				acc[deviceGroup] = (acc[deviceGroup] || 0) + 1;
			}
			return acc;
		}, {});
		setDeviceGroupUserCount(groupCount);

		const contentGroupCount = users.reduce((acc, user) => {
			user.ContentGroups.forEach(group => {
				acc[group.Id] = (acc[group.Id] || 0) + 1;
			});
			return acc;
		}, {});
		setContentGroupUserCount(contentGroupCount);

		if (edit) {
			const updatedContentGroups = contentGroupOptions.map(group => {
				const userCount = contentGroupCount[group.Id] || 0;
				if (userCount === users.length) {
					return { Id: group.Id, Name: group.Name };
				} else if (userCount > 0) {
					return { Id: group.Id, Name: group.Name, mixed: true };
				}
				// Not selected
				return null;
			}).filter(Boolean);

			setFormData(prevData => ({
				...prevData,
				contentGroups: updatedContentGroups
			}));
		}

		// Check if any user has IsSelf true, and disable delete button if so
		const isSelfPresent = users.some(user => user.IsSelf);
		setDisableDelete(isSelfPresent); // Set disableDelete state based on IsSelf
	}, [users, edit]);

	useEffect(() => {
		// Check if all required fields are filled when not in edit mode
		if (!edit) {
			const { firstName, lastName, username, roleId } = formData;
			setCanSave(!!firstName && !!lastName && !!username && roleId > 0);
		}
	}, [formData, edit]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'roleId' && formData.roleId === -1) {
			return;
		}
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleCheckboxChange = (e, group, groupType) => {
		const { checked } = e.target;
		setFormData((prevData) => {
			let updatedGroups;
			if (checked) {
				updatedGroups = [...prevData[groupType], { Id: group.Id, Name: group.Name }];
			} else {
				updatedGroups = prevData[groupType].filter(g => g.Id !== group.Id);
			}
			return { ...prevData, [groupType]: updatedGroups };
		});
	};

	const handleRadioChange = (e, group) => {
		setFormData((prevData) => ({
			...prevData,
			deviceGroup: { Id: group.Id, Name: group.Name }
		}));
	};

	const handleIconClick = (group) => {
		alert(`Device Group ID: ${group.Id}, Name: ${group.Name}`);
	};

	const handleDeleteClick = () => {
		setConfirmation({ action: ActionType.Delete, visible: true });
	};

	const handleResendClick = () => {
		setConfirmation({ action: ActionType.Resend, visible: true });
	};

	const handleSubmitClick = () => {
		if (firstNameRef.current) {
			firstNameRef.current.blur();
		}
		if (lastNameRef.current) {
			lastNameRef.current.blur();
		}
		setConfirmation({ action: edit ? ActionType.Update : ActionType.Add, visible: true });
	};

	const handleConfirm = () => {
		if (confirmation.action === ActionType.Delete) { //fetch step 2
			console.debug("Delete confirmed for the following users:");
			users.forEach(user => {
				console.debug(`User ID: ${user.Id}, Name: ${user.FullName}`);
			});
			setFetchStep(2);
		} else if (confirmation.action === ActionType.Resend) { // fetch step 3
			console.debug("Resend registration email confirmed for the following users:");
			users.forEach(user => {
				console.debug(`User ID: ${user.Id}, Name: ${user.FullName}`);
			});
			setFetchStep(3);
		} else if (confirmation.action === ActionType.Update) { // fetch step 4
			setFinalUsers(updatedUsers.map(user => {
				const updatedUser = { ...user };

				updatedUser.FirstName = formData.firstName;
				updatedUser.LastName = formData.lastName;
				updatedUser.Username = formData.username;
				updatedUser.RoleId = formData.roleId;

				const initialUser = initialUsers.find(u => u.Id === user.Id);
				const initialContentGroups = initialUser.ContentGroups.map(group => group.Name);
				updatedUser.ContentGroups = initialUser.ContentGroups.filter(group =>
					!contentGroupOptions.includes(group.Name) || formData.contentGroups.some(fg => fg.Name === group.Name)
				);

				formData.contentGroups.forEach(group => {
					if (group.Name && !initialContentGroups.includes(group.Name)) {
						updatedUser.ContentGroups.push({ Id: group.Id, Name: group.Name });
					}
				});

				return {
					Id: updatedUser.Id,
					CompanyId: companyId,
					FirstName: updatedUser.FirstName,
					LastName: updatedUser.LastName,
					Username: updatedUser.Username,
					RoleId: formData.roleId === -1 ? updatedUser.RoleId : formData.roleId,
					CreatedOnRegistration: false,
					Active: true,
					ContentGroups: updatedUser.ContentGroups
				};
			}));

			console.debug("Update confirmed for the following users:");
			users.forEach(user => {
				console.debug(`User ID: ${user.Id}, Name: ${user.FullName}`);
			});
			setFetchStep(4);
		} else if (confirmation.action === ActionType.Add) { // fetch step 5
			console.debug("Add confirmed for the following user:");
			console.debug(`First Name: ${formData.firstName}, Last Name: ${formData.lastName}, Email: ${formData.username}`);
			setFetchStep(5);
		}
		setConfirmation({ action: null, visible: false });
	};

	const handleCancel = () => {
		setConfirmation({ action: null, visible: false });
	};

	return (
		<>
			{confirmation.visible ? (
				<ConfirmationDialog
					confirmation={confirmation}
					users={users}
					formData={formData}
					handleConfirm={handleConfirm}
					handleCancel={handleCancel}
				/>
			) : (
				<div className="user-management-modal">
					<DataFetcher fetchData={fetchData} setData={setData} />
						<UserForm
							users={users}
							edit={edit}
							data={data}
							formData={formData}
							handleChange={handleChange}
							handleCheckboxChange={handleCheckboxChange}
							handleRadioChange={handleRadioChange}
							handleIconClick={handleIconClick}
							contentGroupOptions={contentGroupOptions}
							deviceGroupOptions={deviceGroupOptions}
							contentGroupUserCount={contentGroupUserCount}
							deviceGroupUserCount={deviceGroupUserCount}
							firstNameRef={firstNameRef}
							lastNameRef={lastNameRef}
						/>
				</div>
			)}
			{!confirmation.visible && (
				<ActionButtons
					edit={edit}
					handleDeleteClick={handleDeleteClick}
					handleResendClick={handleResendClick}
					handleSubmitClick={handleSubmitClick}
					disableDelete={disableDelete}
					canSave={canSave}
					getDeleteButtonText={() =>
						disableDelete
							? 'You cannot delete yourself'
							: users.length === 1
								? 'Delete User'
								: `Delete ${users.length} Users`
					}
				/>
			)}
		</>
	);
};

export default UserEditor;
