import { useState } from 'react';
import './DropdownButton.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const DropdownButton = ({ text, primaryAction, buttonMenu, disabled }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handlePrimaryClick = () => {
		if (!disabled) {
			if (typeof primaryAction === 'function') {
				primaryAction();
			} else if (typeof primaryAction === 'string') {
				window.location.href = primaryAction;
			}
		}
	};

	const handleMenuClick = (action) => {
		if (!disabled) {
			if (typeof action === 'function') {
				action();
			} else if (typeof action === 'string') {
				window.location.href = action;
			}
			setIsMenuOpen(false);
		}
	};

	const handleMouseEnter = () => {
		if (!disabled) {
			setIsMenuOpen(true);
		}
	};

	const handleMouseLeave = () => {
		if (!disabled) {
			setIsMenuOpen(false);
		}
	};

	return (
		<div
			className={`dropdown-button-container ${disabled ? 'disabled' : ''}`}
			onMouseLeave={handleMouseLeave}
		>
			<button onClick={handlePrimaryClick} disabled={disabled}>
				{text}
			</button>
			<button
				className={"dropdown-button-arrow"}
				style={{ display: 'inline-block' }}
				onMouseEnter={handleMouseEnter}
				disabled={disabled}
			>
				<span
					className={`dropdown-button-arrow-icon ${isMenuOpen ? 'open' : ''}`}
					onClick={() => setIsMenuOpen(!isMenuOpen)}
				>
					<FontAwesomeIcon icon={faChevronDown} />
				</span>
			</button>
			{isMenuOpen && (
				<div className="custom-dropdown-menu">
					{buttonMenu.map((item, index) => (
						item.action ? (
							<div
								key={index}
								className={`${item.disabled ? 'disabled' : ''}`}
								onClick={() => handleMenuClick(item.action)}
							>
								{item.label}
							</div>
						) : (
							<p key={index}>{item.label}</p>
						)
					))}
				</div>
			)}
		</div>
	);
};

export default DropdownButton;
