import { useState, useRef, useCallback } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "components/Button/Button";
import useBranding from "hooks/useBranding";
import "./UserManagement.scss";
import Pod from "components/Pod/Pod";
import DisplaySeats from "./components/DisplaySeats/DisplaySeats";
import DataTable from "components/DataTable/DataTable";
import ContentGroup from "components/ContentGroup/ContentGroup";
import DropdownButton from "components/DropdownButton/DropdownButton";
import { useModal } from "components/Modal/context/ModalContext";
import UserEditor from "./components/UserEditor/UserEditor";
import { getFetchOptions } from "../../utils/fetch";
import { DataFetcher } from "../../components/DataFetcher";
import { useNavigate } from "react-router-dom";
import { ModalButtons } from "components/Modal/ModalButtons";
import { Toast } from "primereact/toast";
import "components/Toast.scss";

const UserManagement = () => {
	const navigate = useNavigate();
	const { gradient, watermark } = useBranding();
	const [fetchData, setFetchData] = useState({ url: "/api/v3/CompanyUser" });
	const initialRequest = useRef(true);
	const [userData, setUserData] = useState(null);
	const maxSeats = userData?.MaxSeats ?? 0;
	const usedSeats = userData?.UsedSeats ?? 0;
	const companyUsers = userData?.CompanyUsers ?? [];
	const companyId = userData?.CompanyId ?? 0;
	const deviceGroupOptions = [];
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [hoveredGroupId, setHoveredGroupId] = useState(null);
	const confirmResend = useRef(false);
	const { openModal, closeModal } = useModal();
	const toast = useRef(null);

	const setFetchRequestData = useCallback((url, options = null) => {
		setFetchData({ url: url, options: options });
	}, []);

	/**
	 * @note Fetch request different success return data:
	 *   1) GET /api/v3/CompanyUser - List<CompanyUserManagementDto>
	 *   2) DELETE /api/v3/CompanyUser - BulkCompanyUserDeleteOutputData
	 *   3) POST /api/v3/ResendCompleteRegistration - TRUE
	 *   4) PUT /api/v3/UnassignUserContentGroups - TRUE
	 */
	const handleData = useCallback((data) => {
		if (data) {
			if (initialRequest.current) {
				setUserData(data);
				initialRequest.current = false;
			}
			else if (typeof (data) === "boolean" && confirmResend.current) {
				const message = (selectedUsers.length === 1)
					? "Registration email sent to " + selectedUsers[0].Username
					: `Registration email sent to ${selectedUsers.length} users`;
				toast.current.show({ severity: "info", summary: "Info", detail: message });
				confirmResend.current = false;
				setSelectedUsers([]);
			}
		}
	}, [selectedUsers]);

	const handleConfirmDelete = (yes) => {
		if (yes) {
			const selectedIds = selectedUsers.map(user => user.Id);
			const options = getFetchOptions({
				method: "DELETE",
				body: selectedIds
			});
			setFetchRequestData("/api/v3/CompanyUser", options);
			setSelectedUsers([]);
		}
	};

	const handleConfirmResend = (yes) => {
		if (yes) {
			const selectedUsernames = selectedUsers.map(user => user.Username);
			const options = getFetchOptions({
				method: "POST",
				body: selectedUsernames
			});
			confirmResend.current = true;
			setFetchRequestData("/api/v3/ResendCompleteRegistration", options);
		}
		else {
			setSelectedUsers([]);
		}
	};
	
	const closeModalAndRefresh = () => {
		closeModal();
		navigate(0);
	}
	
	const handleOpenAddModal = () => {
		openModal("Add User", <UserEditor edit={false} companyId={companyId} onClose={closeModalAndRefresh} />, ModalButtons.None);
	};

	const handleOpenEditModal = (clickedUser) => {
		const usersToEdit = selectedUsers.length > 0 ? selectedUsers : [clickedUser];
		const editModalTitle = usersToEdit.length > 1 ? `Edit ${usersToEdit.length} Users` : "Edit User";
		openModal(editModalTitle, <UserEditor edit={true} companyId={companyId} users={usersToEdit} onClose={closeModalAndRefresh} />, ModalButtons.None);
	};

	const handleOpenConfirmDeleteModal = () => {
		const modalContent = (<>
			<p>Are you sure you want to delete the following user{selectedUsers.length > 1 ? 's' : ''}?</p>
			<ul className="message-user-list">
				{selectedUsers.map(user => (
					<li key={user.Id} className="message-user-list-item">{user.FullName}</li>
				))}
			</ul>
		</>);
		openModal("Confirm User Deletion", modalContent, ModalButtons.YesNo, handleConfirmDelete);
	};

	const handleOpenConfirmResendRegistrationEmailModal = () => {
		const modalContent = (<>
			<p>Are you sure you want to resend the registration email to the following user{selectedUsers.length > 1 ? 's' : ''}?</p>
			<ul className="message-user-list">
				{selectedUsers.map(user => (
					<li key={user.Id} className="message-user-list-item">{user.FullName}</li>
				))}
			</ul>
		</>);
		openModal("Confirm User Deletion", modalContent, ModalButtons.YesNo, handleConfirmResend);
	};

	const toggleSelectAll = (checked) => {
		setSelectAll(checked);
		setSelectedUsers(checked ? companyUsers : []);
	};
	
	const handleSelectChange = (selected, user) => {
		if (selected) {
			setSelectedUsers(prevSelected => [...prevSelected, user]);
		}
		else {
			setSelectedUsers(prevSelected => prevSelected.filter(u => u.Id !== user.Id));
		}
	};

	const handleUnassignGroup = (groupId, rowData) => {
		const updatedUser = {
			Id: rowData.Id,
			CompanyId: rowData.CompanyId,
			Username: rowData.Username,
			ContentGroups: rowData.ContentGroups.filter(group => group.Id === groupId),
		};
		let unassignableSelectedUsers;
		if (selectedUsers.length === 0) {
			unassignableSelectedUsers = [updatedUser];
		}
		else {
			const updatedSelectedUsers = selectedUsers.map(user => {
				if (user.Id === rowData.Id) {
					return updatedUser;
				}
				return user;
			});
			unassignableSelectedUsers = updatedSelectedUsers;
		}
		const options = getFetchOptions({
			method: "PUT",
			body: unassignableSelectedUsers
		});
		setFetchRequestData("/api/v3/UnassignUserContentGroups", options);
		setSelectedUsers([]);
	};

	const handleMouseToggleGroup = (groupId) => {
		setHoveredGroupId(groupId);
	};

	const buildUserListForAction = (clickedUser) => {
		let finalUserList = selectedUsers.length > 0 ? [...selectedUsers] : [];

		if (!finalUserList.some(user => user.Id === clickedUser.Id)) {
			finalUserList.push(clickedUser);
			setSelectedUsers(finalUserList);
		}
	};

	const deleteUser = (clickedUser) => {
		buildUserListForAction(clickedUser);
		handleOpenConfirmDeleteModal();
	};

	const resendRegistrationEmail = (clickedUser) => {
		buildUserListForAction(clickedUser);
		handleOpenConfirmResendRegistrationEmailModal();
	};
	
	const getButtonActionMenu = (rowData) => {
		let menu = [];
		if (selectedUsers.length > 0) {
			menu.push({ label: `${selectedUsers.length} ${selectedUsers.length === 1 ? "user" : "users"} selected` });
		}
		menu.push({ label: 'Edit', action: () => handleOpenEditModal(rowData) });

		if (selectedUsers.length <= 1) {
			menu.push({ label: "Resend Registration Email", action: () => resendRegistrationEmail(rowData), disabled: rowData.LastLogin !== null });
			menu.push({ label: "Delete User", action: () => deleteUser(rowData), disabled: rowData.IsSelf });
		}
		else {
			const allRegistered = selectedUsers.every(user => user.LastLogin !== null);
			menu.push({ label: 'Resend Registration Email', action: () => resendRegistrationEmail(rowData), disabled: allRegistered });
			
			const selectedUsersExcludingSelf = selectedUsers.filter(user => !user.IsSelf);
			const deleteDisabled = (selectedUsersExcludingSelf.length === 1 && rowData.IsSelf);
			menu.push({
				label: `Delete ${selectedUsersExcludingSelf.length} User${selectedUsersExcludingSelf.length > 1 ? "s" : ""}`,
				action: () => deleteUser(rowData),
				disabled: deleteDisabled
			});
		}
		return menu;
	};

	const selectTemplate = (rowData) => (
		<Checkbox
			onChange={(e) => handleSelectChange(e.checked, rowData)}
			checked={selectedUsers.some(user => user.Id === rowData.Id)}
		/>
	);

	const deviceGroupTemplate = (rowData) => {
		if (rowData.DeviceGroups.length === 0) {
			return <p>No devices assigned</p>;
		}

		return rowData.DeviceGroup.map(group => (
			<span key={group.Id} className="device-group-item">
				{group.Name}
			</span>
		));
	};

	const contentGroupsTemplate = (rowData) => {
		const isRowSelected = selectedUsers.some(user => user.Id === rowData.Id);
		const disableGroups = selectedUsers.length > 0 && !isRowSelected;

		if (rowData.ContentGroups.length === 0) {
			return <p>No groups assigned</p>;
		}

		return rowData.ContentGroups.map(group => (
			<ContentGroup
				key={group.Id}
				id={group.Id}
				name={group.Name}
				onRemove={() => handleUnassignGroup(group.Id, rowData)}
				disabled={disableGroups}
				hovered={isRowSelected && hoveredGroupId === group.Id && selectedUsers.length > 0}
				onMouseEnter={() => isRowSelected && handleMouseToggleGroup(group.Id)}
				onMouseLeave={() => isRowSelected && handleMouseToggleGroup(null)}
			/>
		));
	};

	const editTemplate = (rowData) => {
		const isDisabled = selectedUsers.length > 0 && !selectedUsers.some(user => user.Id === rowData.Id);
		return (
			<DropdownButton text={"Edit"} primaryAction={() => handleOpenEditModal(rowData)} buttonMenu={getButtonActionMenu(rowData)} disabled={isDisabled} />
		);
	};
	
	const columns = [
		{
			headerName: <Checkbox onChange={(e) => toggleSelectAll(e.checked)} checked={selectAll} />,
			valueGetter: selectTemplate,
			style: { width: '40px', paddingRight: '20px' },
			sortable: false
		},
		{
			field: 'FullName',
			headerName: 'Name',
			valueGetter: (data) => { return data.FullName; }
		},
		{
			field: 'Username',
			headerName: 'Email',
			valueGetter: (data) => { return data.Username; }
		},
		...(deviceGroupOptions.length > 0 ? [{
			field: 'DeviceGroup',
			headerName: 'Device Group',
			valueGetter: deviceGroupTemplate,
			sortable: false
		}] : []),
		{
			field: 'ContentGroups',
			headerName: 'Content Groups',
			valueGetter: contentGroupsTemplate,
			sortable: false
		},
		{
			headerName: 'Edit',
			valueGetter: editTemplate,
			style: { width: '140px' },
			sortable: false
		}
	];
	
	const rowClassName = (rowData) => {
		return selectedUsers.some(user => user.Id === rowData.Id) ? 'active' : '';
	};

	const render = () => {
		if (!userData) {
			return <DataFetcher fetchData={fetchData} setData={handleData} />;
		}
		return (
			<>
				{userData && <DataFetcher fetchData={fetchData} setData={handleData} />}
				<Pod title="User Management" gradient={gradient} watermark={watermark}>
					<DisplaySeats maxSeats={maxSeats} usedSeats={usedSeats} />
					<div className="datatable-pod">
						<div className="datatable-pod-buttons">
							<Button text="Add User" onClick={handleOpenAddModal} />
						</div>
						<DataTable
							rows={companyUsers}
							columns={columns}
							rowsPerPage={10}
							rowClassName={rowClassName}
						/>
						<div className="datatable-pod-buttons">
							<Button text="Add User" onClick={handleOpenAddModal} />
						</div>
					</div>
				</Pod>
				<Toast ref={toast} />
			</>
		);
	}
	
	return (
		<div className="grid-full gutters">{render()}</div>
	);
};

export default UserManagement;
