import React from 'react';
import './UserEditor.scss';
import { Button, ButtonType } from 'components/Button/Button';

const ActionButtons = ({
	edit,
	handleDeleteClick,
	handleResendClick,
	handleSubmitClick,
	disableDelete,
	canSave,
	getDeleteButtonText,
}) => (
	<div className="modal-buttons">
		{edit && (
			<>
				<Button
					type={ButtonType.Delete}
					text={getDeleteButtonText()}
					onClick={handleDeleteClick}
					disabled={disableDelete}
				/>
				<Button type={ButtonType.Secondary} text="Resend Registration" onClick={handleResendClick} />
			</>
		)}
		<Button
			type={ButtonType.Primary}
			text="Save"
			onClick={handleSubmitClick}
			disabled={!canSave && !edit}
		/>
	</div>
);

export default ActionButtons;
