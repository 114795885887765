import { DataFetcher } from "components/DataFetcher";
import Pod from "components/Pod/Pod";
import useBranding from "hooks/useBranding";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getFetchOptions } from "utils/fetch";
import QuickSearch from "../QuickSearch";
import SearchResultsList from "./components/SearchResultsList";

const SearchResults = () => {
	const { state: searchTerm } = useLocation();
	const { gradient, watermark } = useBranding();
	const [fetchData, setFetchData] = useState({});
	const [searchResults, setSearchResults] = useState([]);

	useEffect(() => {
		const options = getFetchOptions({
			method: "POST",
			body: searchTerm
		});
		setFetchData({ url: "api/Search/", options: options });
	}, [searchTerm]);

	return (
		<div className="grid-two-col gutters">
			<DataFetcher fetchData={fetchData} setData={setSearchResults}>
				<Pod title="Search Results" gradient={gradient} watermark={watermark}>
					<SearchResultsList searchTerm={searchTerm} searchResults={searchResults}></SearchResultsList>
				</Pod>
				<Pod title="Search" gradient={gradient} watermark={watermark} mobileOrder={2} sticky>
					<QuickSearch />
				</Pod>
			</DataFetcher>
		</div>
	);
};

export default SearchResults;